import { createContext, useContext, useEffect, useState } from "react";

export type Settings = {
  themeMode: "dark" | "light";
};

const SETTINGS_KEY = "todobin_settings";

const defaultSettings: Settings = {
  themeMode: "light"
} as const;

export const SettingsContext = createContext<
  [Settings, (newSettings: Settings) => void]
>(null!);

export const SettingsProvider = ({
  children
}: {
  children?: React.ReactNode;
}) => {
  const [settings, setSettings] = useState<Settings>(
    JSON.parse(localStorage.getItem(SETTINGS_KEY)) ?? defaultSettings
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
  }, [settings]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {!loading && children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
