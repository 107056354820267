import { useSettings } from "Settings";
import styled from "styled-components";
import { Flex } from "ui";
import logoSrc from "../img/logo.svg";
import logoDarkSrc from "../img/logodark.svg";
import { CenterColumn } from "./CenterColumn";
import { DarkModeToggle } from "./DarkModeToggle";

const Container = styled.div`
  height: 60px;
  background: ${(props) => props.theme.colors.panel};
  transition: background-color 0.3s ease;
  padding: 6px 24px;
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Logo = styled.img`
  height: 45px;
`;

const Quote = styled.p`
  margin: 0;
  margin-left: 36px;
  font-style: italic;
  color: #999;
`;

Logo.defaultProps = {
  src: logoSrc
};

export function Navbar() {
  const [settings, setSettings] = useSettings();

  return (
    <Container>
      <CenterColumn>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Logo
              src={settings.themeMode === "light" ? logoSrc : logoDarkSrc}
            />
            <Quote>"Pastebin, but for todos"</Quote>
          </Flex>
          <DarkModeToggle
            value={settings.themeMode}
            onChange={(val) => setSettings({ themeMode: val })}
          />
        </Flex>
      </CenterColumn>
    </Container>
  );
}
