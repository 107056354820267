import { Settings } from "Settings";
import { DefaultTheme } from "styled-components";

const baseline = 6;
const baseFontSize = 16;
const borderRadius = 5;

const calcLineHeight = (size: number) =>
  ((Math.ceil(size / baseline) + 1) * baseline) / size;

// Major third typography
const calcFontsize = (base: number, order: number) =>
  base * Math.pow(1.25, order);

const fontStyle = (order: number, fontFamily?: string) => {
  const fontSize = Math.round(calcFontsize(baseFontSize, order));
  const lineHeight = calcLineHeight(fontSize);
  return {
    fontSize,
    lineHeight,
    fontFamily
  };
};

const breakpoints = ["768px", "1024px", "1400px", "1920px"];

// Aliases
const breakpointNames = ["sm", "md", "lg", "xl"];
breakpointNames.forEach((x, i) => (breakpoints[x] = breakpoints[i]));

const lightColors = {
  body: "#3e464c",
  primary: "#f76c46",
  background: "#f8f8f8",
  backgroundLight: "#34495e",
  turquoise: "#16a085",
  turquoiseLight: "#1abc9c",
  green: "#27ae60",
  greenLight: "#2ecc71",
  blue: "#2980b9",
  blueLight: "#3498db",
  purple: "#8e44ad",
  purpleLight: "#9b59b6",
  silver: "#7f8c8d",
  silverLight: "#95a5a6",
  red: "#c0392b",
  redLight: "#e74c3c",
  danger: "#c0392b",
  panel: "#ffffff"
};

const darkColors = {
  background: "#292d30",
  panel: "#3e464c",
  primary: "#f76c46",
  body: "#f8f8f8",
  backgroundLight: "#34495e",
  turquoise: "#16a085",
  turquoiseLight: "#1abc9c",
  green: "#27ae60",
  greenLight: "#2ecc71",
  blue: "#2980b9",
  blueLight: "#3498db",
  purple: "#8e44ad",
  purpleLight: "#9b59b6",
  silver: "#7f8c8d",
  silverLight: "#95a5a6",
  red: "#c0392b",
  redLight: "#e74c3c",
  danger: "#c0392b"
};

const lightShadows = ["none", "rgba(99, 99, 99, 0.2) 0px 2px 4px 0px"];
const darkShadows = ["none", "rgba(0, 0, 0, 0.2) 0px 2px 4px 0px"];

const theme: DefaultTheme = {
  baseline,
  baseLineHeight: calcLineHeight(baseFontSize),
  baseFontSize,
  borderRadius,
  breakpoints,
  shadows: ["none", "rgba(99, 99, 99, 0.2) 0px 2px 4px 0px"],
  boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
  fontSizes: [],
  mode: "light",
  fonts: {
    heading: "neue-haas-grotesk-display,sans-serif",
    body: "neue-haas-grotesk-text, sans-serif"
  },
  textStyles: {
    canon: fontStyle(4),
    doubleGreatPrimer: fontStyle(3),
    doublePica: fontStyle(2),
    greatPrimer: fontStyle(1),
    english: fontStyle(0),
    pica: fontStyle(-1),
    longPrimer: fontStyle(-1.5)
  },
  colors: lightColors,
  space: [0, 6, 12, 18, 24, 36, 48, 72, 96]
};

export function getTheme(settings: Settings): DefaultTheme {
  return {
    ...theme,
    mode: settings.themeMode,
    colors: settings.themeMode === "dark" ? darkColors : lightColors,
    shadows: settings.themeMode === "dark" ? darkShadows : lightShadows
  };
}

export default theme;
