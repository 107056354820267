import styled from "styled-components";
import {
  boxShadow,
  BoxShadowProps,
  layout,
  LayoutProps,
  space,
  SpaceProps
} from "styled-system";

export const Panel = styled.div<SpaceProps & BoxShadowProps & LayoutProps>`
  background-color: ${(props) => props.theme.colors.panel};
  border-radius: 6px;
  transition: background-color 0.3s ease;
  ${space};
  ${boxShadow};
  ${layout};
`;

Panel.defaultProps = {
  p: 3,
  boxShadow: 1,
  width: "100%"
};
