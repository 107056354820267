import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { getTheme } from "./ui/theme";

import GlobalStyle from "./GlobalStyle";

import ListView from "./pages/ListView";
import CreateList from "./pages/CreateList";
import { MainRoute } from "ui/MainRoute";
import { SettingsProvider, useSettings } from "Settings";

const Container = styled.div`
  width: 100%;
`;

const UserThemeProvider = ({ children }) => {
  const [settings] = useSettings();
  return <ThemeProvider theme={getTheme(settings)}>{children}</ThemeProvider>;
};

function App() {
  return (
    <SettingsProvider>
      <UserThemeProvider>
        <Container>
          <GlobalStyle />
          <Router>
            <Switch>
              <MainRoute path="/" exact component={CreateList} />
              <MainRoute path="/:slug" exact component={ListView} />
            </Switch>
          </Router>
        </Container>
      </UserThemeProvider>
    </SettingsProvider>
  );
}

export default App;
