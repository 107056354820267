import React from "react";
import { IconType } from "react-icons";
import styled, { DefaultTheme } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { lighten } from "polished";

export type ButtonProps = React.ComponentPropsWithRef<"button"> & {
  icon?: IconType;
  tight?: boolean;
  color?: keyof DefaultTheme["colors"];
} & SpaceProps;

export const ButtonElem = styled.button<{
  hasIcon: boolean;
  tight?: boolean;
  color: keyof DefaultTheme["colors"];
}>`
  border: 0;
  border-radius: 6px;
  padding: ${(props) => (props.hasIcon || props.tight ? "0 8px" : "6px 24px")};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: bold;
  background: ${(props) => props.theme.colors[props.color]};
  height: 31px;
  color: white;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors[props.color]};
  ${space};

  &:hover {
    background: ${(props) => lighten(0.1, props.theme.colors[props.color])};
    border-color: ${(props) => lighten(0.1, props.theme.colors[props.color])};
  }

  &:active,
  &:focus {
    outline: 0;
    border-color: ${(props) => lighten(0.1, props.theme.colors[props.color])};
  }
`;

const iconStyle: React.CSSProperties = {
  position: "relative",
  top: 0
};

export const Button = ({
  icon: Icon,
  children,
  color = "primary",
  ...props
}: ButtonProps) => (
  <ButtonElem {...props} hasIcon={Icon != null} color={color}>
    {Icon ? <Icon style={iconStyle} /> : children}
  </ButtonElem>
);
