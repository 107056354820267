import { IconType } from "react-icons";
import styled, { DefaultTheme } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { lighten } from "polished";

export type IconButtonProps = {
  icon: IconType;
  color?: keyof DefaultTheme["colors"];
} & SpaceProps &
  React.ComponentPropsWithoutRef<"button">;

type ElemProps = {
  color: keyof DefaultTheme["colors"];
} & SpaceProps;

const Elem = styled.button<ElemProps>`
  ${space};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border: 0;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) => lighten(0.2, props.theme.colors[props.color])};

  &:hover {
    background-color: #eee;
    color: ${(props) => props.theme.colors[props.color]};
  }
`;

export function IconButton({
  icon: Icon,
  color = "body",
  ...rest
}: IconButtonProps) {
  return (
    <Elem color={color} {...rest}>
      <Icon />
    </Elem>
  );
}
