import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const Input = styled.input<SpaceProps>`
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 6px 12px;
  ${space};

  &:active,
  &:focus {
    outline: 0;
    border-color: ${(props) => props.theme.colors.primary};
  }
`;
