import axios from "axios";
import {
  SubscriptionConfirmationSpec,
  SubscriptionLevel,
  SubscriptionSpec,
  TodoList
} from "@todobin/types";
import React, { useEffect, useRef, useState } from "react";
import { FaCalendar, FaCheck, FaTrash } from "react-icons/fa";
import styled from "styled-components";
import {
  Panel,
  Button,
  Flex,
  ListName,
  ListInput,
  Hider,
  Input,
  IconButton
} from "../ui";
import useInterval from "../useInterval";
import useOnClickOutside from "../useOnClickOutside";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import queryString from "query-string";

import "react-datepicker/dist/react-datepicker.css";
import Modal from "../ui/Modal";
import { Text } from "../ui/Typography";
import { useHistory, useLocation, useParams } from "react-router";

const Container = styled.div``;

const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ItemName = styled.span<{ complete: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.complete ? "#999" : "inherit")};
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.span<{ complete: boolean }>`
  cursor: pointer;
  border: 1px solid #ddd;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.complete ? "white" : props.theme.colors.background};
  margin-right: 15px;
  background-color: ${(props) =>
    props.complete
      ? props.theme.colors.primary
      : props.theme.colors.background};
  border-color: ${(props) =>
    props.complete ? props.theme.colors.primary : "#ddd"};
  transition: all 0.3s ease;

  svg {
    position: relative;
    top: 1px;
  }
`;

const UserContainer = styled.div`
  position: relative;
`;

const UserMenu = styled.div<{ open: boolean }>`
  position: absolute;
  background: white;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
  top: calc(100% + 10px);
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transform: scale(${(props) => (props.open ? 1 : 0)});
  transform-origin: top right;
  transition: all 0.3s ease;
  z-index: 100;
  text-align: center;
`;

const DueLabel = styled.span`
  font-size: 12px;
  color: #aaa;
  background-color: #eee;
  padding: 3px 6px;
  border-radius: 3px;
`;

const DateSelector = ({ value, onChange }) => {
  const [isOpen, setOpen] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <UserContainer ref={ref}>
      {value ? (
        <DueLabel>{"due " + format(new Date(value), "dd/MM/yy")}</DueLabel>
      ) : null}
      <IconButton
        ml={1}
        onClick={() => setOpen(!isOpen)}
        color="silver"
        icon={FaCalendar}
      />
      <UserMenu open={isOpen}>
        <DatePicker inline selected={value} onChange={onChange} />
        <Button
          onClick={() => {
            onChange(null);
            setOpen(false);
          }}
        >
          Clear Date
        </Button>
      </UserMenu>
    </UserContainer>
  );
};

const FlexLine = styled.div`
  display: flex;
  align-items: center;
`;

const ListView = () => {
  const [isLoading, setLoading] = useState<Boolean>(true);
  const [list, setList] = useState<TodoList | null>(null);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [showSubscribe, setShowSubscribe] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [subscribing, setSubscribing] = useState<boolean>(false);
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();
  const history = useHistory();
  const { confirm } = queryString.parse(location.search);

  const [confirming, setConfirming] = useState<number>(confirm != null ? 1 : 0);

  const confirmSubscription = (level: SubscriptionLevel) => {
    setConfirming(2);
    const spec: SubscriptionConfirmationSpec = {
      level
    };
    axios.put(`/api/subscriptions/${confirm}/confirm`, spec).then((x) => {
      if (x.status === 204) {
        alert("Email confirmed");
        setConfirming(0);
        history.push("/" + list.slug);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/lists/${slug}`).then(({ data }) => {
      setList(data);
      setLoading(false);
    });
  }, [slug]);

  useInterval(() => {
    axios.get(`/api/lists/${slug}`).then(({ data }) => {
      setList(data);
    });
  }, 10000);

  const save = (list) => {
    if (isSaving) return;
    setSaving(true);
    axios.put(`/api/lists/${list.slug}`, list).then(({ data }) => {
      setList(data);
      setSaving(false);
    });
  };

  const subscribe = () => {
    setSubscribing(true);
    const spec: SubscriptionSpec = {
      slug: list.slug,
      email: userEmail
    };
    axios.post("/api/subscriptions", spec).then((x) => {
      setShowSubscribe(false);
      setSubscribing(false);
    });
  };

  const toggle = (itemId) => {
    const toToggle = list.items.find((x) => x.id === itemId);
    toToggle.status = toToggle.status === "done" ? "created" : "done";

    const newList = {
      ...list
    };
    setList(newList);
    save(newList);
  };

  const setDueDate = (itemId: number, d: Date | null) => {
    const toToggle = list.items.find((x) => x.id === itemId);
    if (toToggle.dateDue === d) {
      return;
    }
    toToggle.dateDue = d;

    const newList = {
      ...list
    };
    setList(newList);
    save(newList);
  };

  const removeItem = (itemId: number) => {
    if (window.confirm("Are you sure?")) {
      list.items = list.items.filter((x) => x.id !== itemId);
      const newList = {
        ...list
      };
      setList(newList);
      save(newList);
    }
  };

  const setListName = (name: string) => {
    const newList = {
      ...list,
      name
    };
    setList(newList);
    save(newList);
  };

  const onKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.which === 13) {
      const newList: TodoList = {
        ...list,
        items: [
          ...list.items,
          {
            text: ev.currentTarget.value,
            status: "created",
            dateDue: null,
            dateCompleted: null,
            dateDueChanged: null,
            dateCreated: new Date()
          }
        ]
      };
      setList(newList);
      save(newList);
      ev.currentTarget.value = "";
    }
  };

  const cancelConfirmation = () => {
    setConfirming(0);
    history.push("/" + list.slug);
  };

  console.log(isLoading);

  return (
    <Container>
      <Panel p={[3, 5]}>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <div>
            <Modal shown={confirming > 0}>
              <Text as="h1" mb={3} fontWeight={600}>
                Subscribe to List
              </Text>
              <Flex flexDirection="column">
                <Button
                  mb={2}
                  onClick={() => confirmSubscription("all")}
                  disabled={confirming === 2}
                >
                  Subscribe to list
                </Button>
                <Button
                  onClick={cancelConfirmation}
                  disabled={confirming === 2}
                >
                  Do not subscribe
                </Button>
              </Flex>
            </Modal>
            <Flex justifyContent="space-between" alignItems="center" mb="3">
              <ListName
                value={list.name || "Untitled List"}
                onChange={setListName}
              />
              <Button
                onClick={() => setShowSubscribe(!showSubscribe)}
                disabled={subscribing}
              >
                Subscribe to List
              </Button>
            </Flex>
            <Hider open={showSubscribe}>
              <p>
                Subscribe to this list to receive updates on progress and
                looming due dates. <br />
                We'll ask you to confirm your email address before you receive
                any updates.
              </p>
              <Flex>
                <Input
                  placeholder="Enter your email..."
                  mr="2"
                  value={userEmail}
                  onChange={(ev) => setUserEmail(ev.target.value)}
                />
                <Button onClick={subscribe} disabled={subscribing}>
                  {subscribing ? "Please wait..." : "Subscribe"}
                </Button>
              </Flex>
            </Hider>

            <ListInput
              placeholder="Add new items here..."
              onKeyPress={onKeyPress}
            />
            <ItemList>
              {list.items.map((x, i) => (
                <Item key={i}>
                  <ItemName complete={x.status === "done"}>
                    <Icon
                      complete={x.status === "done"}
                      onClick={() => x.id != null && toggle(x.id)}
                    >
                      <FaCheck size="0.8em" />
                    </Icon>
                    {x.text}
                  </ItemName>
                  <FlexLine>
                    <DateSelector
                      value={x.dateDue ? new Date(x.dateDue) : x.dateDue}
                      onChange={(d) => setDueDate(x.id, d)}
                    />
                    <IconButton
                      icon={FaTrash}
                      color="danger"
                      onClick={() => removeItem(x.id)}
                    />
                  </FlexLine>
                </Item>
              ))}
            </ItemList>
          </div>
        )}
      </Panel>
    </Container>
  );
};

export default ListView;
