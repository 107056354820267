import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Canon } from "./Typography";
import useOnClickOutside from "../useOnClickOutside";
import { FaEdit } from "react-icons/fa";
import { Flex } from "./";

const Heading = styled(Canon)`
  display: inline;
`;

const EditButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  color: #7f8c8d;

  &:hover {
    color: ${(props) => props.theme.colors.body};
  }
`;

export const ListName = ({ value, onChange }) => {
  const [editing, setEditing] = useState(false);

  const ref = useRef<HTMLDivElement>(null!);

  useOnClickOutside(ref, () => {
    if (editing) {
      setEditing(false);
      onChange(ref.current.firstElementChild.innerHTML);
    }
  });

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.which === 13 && editing) {
      setEditing(false);
      onChange((e.target as any).innerHTML);
    }
  };

  function selectElementContents(el: HTMLElement) {
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  const edit = () => {
    setEditing(true);
    setTimeout(() => {
      (ref.current.firstElementChild as HTMLElement).focus();
      selectElementContents(ref.current.firstElementChild as HTMLElement);
    }, 100);
  };

  return (
    <Flex ref={ref} alignItems="flex-start">
      <Heading as="h1" bold contentEditable={editing} onKeyPress={onKeyPress}>
        {value}
      </Heading>
      {!editing && (
        <EditButton onClick={edit}>
          <FaEdit />
        </EditButton>
      )}
    </Flex>
  );
};
