import React from "react";
import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";
import theme from "./theme";

export type TextProps = {
  uppercase?: boolean;
  bold?: boolean;
} & TypographyProps &
  SpaceProps;

export const Text = styled.div<TextProps>`
  ${space}
  ${typography}
  font-weight: ${(props) => (props.bold ? 600 : 500)};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : null)};
`;

export const Canon = (props: TextProps) => (
  <Text {...theme.textStyles.canon} {...props} />
);

export const DoubleGreatPrimer = (props: TextProps) => (
  <Text {...theme.textStyles.doubleGreatPrimer} {...props} />
);

export const DoublePica = (props: TextProps) => (
  <Text {...theme.textStyles.doublePica} {...props} />
);

export const GreatPrimer = (props: TextProps) => (
  <Text {...theme.textStyles.greatPrimer} {...props} />
);

export const English = (props: TextProps) => (
  <Text {...theme.textStyles.english} {...props} />
);

export const Pica = (props: TextProps) => (
  <Text {...theme.textStyles.pica} {...props} />
);

export const LongPrimer = (props: TextProps) => (
  <Text {...theme.textStyles.longPrimer} {...props} />
);
