import styled from "styled-components";

export const ListInput = styled.input`
  border: 0;
  border-bottom: 1px solid #ddd;
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 5px;
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: transparent;

  &:focus,
  &:active {
    outline: 0;
    border-color: ${(props) => props.theme.colors.primary};
  }
`;
