import { FaMoon, FaSun } from "react-icons/fa";
import styled from "styled-components";

const Elem = styled.div<{ on: boolean }>`
  height: 32px;
  width: 54px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 2px 0px inset,
    rgba(255, 255, 255, 0.1) -1px -1px 3px 0px inset;
  transition: all 0.3s ease;
  background-color: ${(props) => props.theme.colors.background};

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    position: relative;
    top: 4px;
    left: 4px;
    height: 24px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.panel};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(${(props) => (props.on ? 21 : 0)}px, 0, 0);
    transition: all 0.3s ease;
    color: ${(props) => props.theme.colors.primary};

    & > svg {
      transition: all 0.3s ease;
      position: absolute;
    }
  }
`;

export type DarkModeToggleProps = {
  value: "dark" | "light";
  onChange: (val: "dark" | "light") => void;
};

export function DarkModeToggle({ value, onChange }: DarkModeToggleProps) {
  return (
    <Elem
      on={value === "dark"}
      onClick={() => onChange(value === "dark" ? "light" : "dark")}
    >
      <span>
        <FaSun style={{ opacity: value === "dark" ? 0 : 1 }} />
        <FaMoon style={{ opacity: value === "dark" ? 1 : 0 }} />
      </span>
    </Elem>
  );
}
