import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

export const CenterColumn = styled.div<SpaceProps & LayoutProps>`
  max-height: 100%;
  ${space};
  ${layout};
`;

CenterColumn.defaultProps = {
  width: ["100%", 700, 1000, 1300, 1600],
  ml: [1, "auto"],
  mr: [1, "auto"]
};
