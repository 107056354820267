import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

const CreateList = () => {
  const [slug, setSlug] = useState<string | null>(null);

  useEffect(() => {
    axios.post("/api/lists").then(({ data }) => {
      setSlug(data.slug);
    });
  }, []);

  return slug == null ? <p>loading</p> : <Redirect to={"/" + slug} />;
};

export default CreateList;
