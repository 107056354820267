import React from "react";
import { Route, RouteProps } from "react-router";
import styled from "styled-components";
import { Navbar } from "./Navbar";
import { CenterColumn } from "./CenterColumn";

const MainContainer = styled.div``;

export function MainRoute({ component, ...rest }: RouteProps) {
  return (
    <MainContainer>
      <Navbar />
      <CenterColumn pt={4}>
        <Route component={component} {...rest} />
      </CenterColumn>
    </MainContainer>
  );
}
