import React from "react";
import styled from "styled-components";
import { Panel } from "./";

const Wrapper = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 64px;
  z-index: 1000;
`;

const Modal = ({ shown, children }) => {
  if (!shown) {
    return null;
  }

  return (
    <Wrapper>
      <Panel p={3}>{children}</Panel>
    </Wrapper>
  );
};

export default Modal;
