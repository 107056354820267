import React, { useRef } from "react";
import styled from "styled-components";

const Container = styled.div<{ height: number }>`
  overflow: hidden;
  transition: all 0.3s ease;
  height: ${(props) => props.height}px;
`;

export const Hider = ({ children, open }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Container
      height={open ? (ref.current ?? { clientHeight: 0 }).clientHeight : 0}
    >
      <div ref={ref}>{children}</div>
    </Container>
  );
};
