import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  
  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.body};
    font-family: ${(props) => props.theme.fonts.body};
    letter-spacing: 0.02em;
    transition: background-color 0.3s ease;
  }

  input, button {
    color: ${(props) => props.theme.colors.body};
    font-family: ${(props) => props.theme.fonts.body};
    letter-spacing: 0.02em;
  }

  input, textarea {
    letter-spacing: 0.02em;
    font-weight: 400;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.background};
  }

  h1 {
    font-size: 1.8em;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    letter-spacing: 0.02em;
    font-family: ${(props) => props.theme.fonts.heading};
    font-weight: 600;
  }

  p {
    margin: 0;
    margin-bottom: 12px;
    line-height: 1.5;
    font-weight: 400;
  }

`;

export default GlobalStyle;
